import React from 'react'
import { getStorage } from '../modals/Storage';
import '../../MainStyle.css';

function PaymentSuccess(){

    return (
      <div className='v-center'>
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <div className="col-view mg-t-45">

            <div className={`w-100 col-view v-center mg-t-55`}>
              <img className="h-70-p" src={require('../icons/check-icon.png')} />

              <p className='ft-sz-20 mg-t-15'>Payment Successful</p>
              <p className='ft-sz-13 mg-t-10'>RefNumber: {getStorage("sessionPayId")}</p>
            </div>

          </div>
        </div>
      </div>
    );
}

export default PaymentSuccess;