import React from 'react'
import { Link  } from 'react-router-dom';
import '../css/navbar.css';

const GameTopBar = ({intentData,updateState}) => {
  
    return (
        <nav className="ps-fx ps-tp res-wth h-45-p pd-10 row-view sb-view z-i-120 bg-white topbar-shadow">
          
          <div className='row-view'>
           <Link onClick={() => updateState("back")}>
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
           </Link>

           <span className={`ft-sz-18 mg-l-15 ${intentData.pageTitle =='' ? 'hide-v' : ''}`}>{intentData.pageTitle}</span>
          </div>

          <div className={`${intentData.paymentStatusCode > 1 ? 'hide-v' : ''}`} onClick={()=>updateState("enterRefCode")}>Enter Ref Num</div>
        </nav>
    );
};

export default GameTopBar;