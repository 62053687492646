import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import './MainStyle.css';

import SuperPay from './components/payments/SuperPay';
import PaymentSuccess from './components/payments/PaymentSuccess';
import PaymentSubmitted from './components/payments/PaymentSubmitted';
import PaymentFailed from './components/payments/PaymentFailed';

function App() {

  return <>
  <Router>

    <Routes>
      {/* default page */}
      <Route path='/' element={<SuperPay/>} />
      <Route path='/success' element={<PaymentSuccess/>} />
      <Route path='/submitted' element={<PaymentSubmitted/>} />
      <Route path='/failed' element={<PaymentFailed/>} />
    </Routes>

  </Router>
  </>;
}

export default App;
