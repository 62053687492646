export function setStorage(sname,svalue) {
   return sessionStorage.setItem(sname, svalue);
}

export function getStorage(sname) {
   return sessionStorage.getItem(sname);
}

export function deleteStorage(){
   sessionStorage.clear();
   return true;
}