import React, { useState } from 'react'
import { setStorage } from '../modals/Storage'

const PaymentFormDialog = ({intentData,updateState}) => {

    const [pageConst, setConstants] = useState({
      inFullName: "",
      formError: "",
      applicationChoosed: "",
    });

    const onInputValChange = (source,data) =>{
        if(source=="name"){
          setConstants(previousState => {
            return { ...previousState, inFullName: data }
          });
        }
    }

    const chooseThisApplication = (data) =>{
        setConstants(previousState => {
            return { ...previousState, applicationChoosed: data }
        });
    }

    const continueToPay = (e) =>{
        if(pageConst.inFullName!=""){
            
            if(pageConst.applicationChoosed!=""){
              let applicationChoosed = pageConst.applicationChoosed;
              if(pageConst.applicationChoosed=="jio" || pageConst.applicationChoosed=="airtel" || pageConst.applicationChoosed=="bhimupi"){
                applicationChoosed = "bhim";
              }

              setStorage("sessionPayName", pageConst.inFullName);
              setStorage("sessionPayApp", applicationChoosed);
              updateState(e,"formsubmitted");
            }else{
              setConstants(previousState => {
                return { ...previousState, formError: "Please Choose Payment App" }
              });
            }
        }else{
            setConstants(previousState => {
                return { ...previousState, formError: "Please Enter your name as per Bank" }
            });
        }
    }

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.paymentDialogShow ? 'activeDialog' : ''}`}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-550-p pd-15 ovf-scrl hide-sb'>
                {/* <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>Super Pay</p> */}

                <div className='col-view pd-5 mg-b-50 w-100'>
                    
                    <div className='ft-sz-14 mg-t-10 cl-grey'>
                    ** Enter your name as per bank account.
                    </div>

                    <div className='cutm-inp-bx pd-5-15 mg-t-10'>
                      <img className="h-w-22" src={require('../icons/user_icon.png')} />
                      <input type="text" className='mg-l-20 ft-sz-18 cutm-inp' placeholder='Enter your name' autoComplete="new-password" onChange={e => onInputValChange('name',e.target.value)}></input>
                    </div>

                    <div className='ft-sz-14 mg-t-30 cl-grey'>
                    ** Which app you will use to complete this transaction?
                    </div>

                    <div className='g-v-3 g-r-gap-20 mg-t-10'>

                        <div className={`col-view v-center pd-10-0 ${pageConst.applicationChoosed=="phonepe" ? 'bg-l-blue br-10' : ''}`} onClick={() => chooseThisApplication('phonepe')}>
                          <div className='h-w-55 v-center ovf-hidden bg-white br-50'>
                            <img className="h-w-45" src={require('../icons/phone_pay_icon.png')} />
                          </div>
                          <p className='mg-t-5'>Phone Pe</p>
                        </div>

                        <div className={`col-view v-center pd-10-0 ${pageConst.applicationChoosed=="google pay" ? 'bg-l-blue br-10' : ''}`} onClick={() => chooseThisApplication('google pay')}>
                          <div className='h-w-55 v-center ovf-hidden bg-white br-50'>
                            <img className="h-w-45" src={require('../icons/google_pay_icon.webp')} />
                          </div>
                          <p className='mg-t-5'>Google Pay</p>
                        </div>

                        <div className={`col-view v-center pd-10-0 ${pageConst.applicationChoosed=="paytm" ? 'bg-l-blue br-10' : ''}`} onClick={() => chooseThisApplication('paytm')}>
                          <div className='h-w-55 v-center ovf-hidden bg-white br-50'>
                            <img className="h-w-45" src={require('../icons/paytm_icon.png')} />
                          </div>
                          <p className='mg-t-5'>Paytm</p>
                        </div>

                        <div className={`col-view v-center pd-10-0 ${pageConst.applicationChoosed=="jio" ? 'bg-l-blue br-10' : ''}`} onClick={() => chooseThisApplication('jio')}>
                          <div className='h-w-55 v-center ovf-hidden bg-white br-50'>
                            <img className="h-w-45" src={require('../icons/jio_icon.png')} />
                          </div>
                          <p className='mg-t-5'>Jio</p>
                        </div>

                        <div className={`col-view v-center pd-10-0 ${pageConst.applicationChoosed=="airtel" ? 'bg-l-blue br-10' : ''}`} onClick={() => chooseThisApplication('airtel')}>
                          <div className='h-w-55 v-center ovf-hidden bg-white br-50'>
                            <img className="h-w-45" src={require('../icons/airtel_icon.png')} />
                          </div>
                          <p className='mg-t-5'>Airtel</p>
                        </div>

                        <div className={`col-view v-center pd-10-0 ${pageConst.applicationChoosed=="bhimupi" ? 'bg-l-blue br-10' : ''}`} onClick={() => chooseThisApplication('bhimupi')}>
                          <div className='h-w-55 v-center ovf-hidden bg-white br-50'>
                            <img className="h-w-28" src={require('../icons/bhim_icon.png')} />
                          </div>
                          <p className='mg-t-5'>Bhim UPI</p>
                        </div>

                    </div>

                </div>
                

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='v-center ft-sz-14 cl-red mg-b-5'>{pageConst.formError}</div>
                  <div className='bg-blue h-45-p ft-sz-18 pd-10 br-10 cl-white v-center w-100' onClick={(e) => continueToPay(e)}>
                    <span>Continue to pay</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default PaymentFormDialog;